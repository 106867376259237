<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item>Manage My Orders</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
            <h1 style="font-size: 1.5em; font-weight: 600;">Manage My Orders:</h1>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Order"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <div>
                <!-- <AddCategoryDialog @re-fresh="getMenuOrders" /> -->
              </div>
            </div>
            <hr />
            <!-- {{ orders }} -->
            <div
              v-loading="loading || is_changing"
              class="table-responsive mb-5"
            >
              <data-tables
                :data="orders"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getMenuOrders"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load MenuOrders Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No MenuOrders</div>
                </div>
                <el-table-column
                  label="OrderNo"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="150px"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.OrderID}} </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="TableOrRoom"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.TableOrRoom }} </span><br />
                    <small>Add On: {{ formattedDate(scope.row.OrderTime) }} </small><br/>
                    <!-- <small>Added By: {{ scope.row.first_name }} {{ scope.row.last_name }}</small> -->
                  </template>
                </el-table-column>
                <el-table-column
                  label="TotalAmount"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.TotalAmount }} </span><br />
                    <small>TaxRate: {{ scope.row.TaxRate }} </small><br/>
                    <small>Discount: {{ scope.row.Discount }}</small><br/>
                    <small>Shipping: {{ scope.row.Shipping }}</small>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Products"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="150px"
                >
                  <template>
                    <el-button
                      type="warning"
                      :disabled="false" round
                    >{{ 2 }} Items</el-button>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="120px"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                        <el-button
                          type="warning"
                          :disabled="false"
                          @click="showEditCategoryData(scope.row)"
                          icon="el-icon-edit"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          :disabled="false"
                          @click="deleteCategory(scope.row.categoryID)"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </article>
      </section>
      <!-- <EditCategoryDialog
        :showEditCategoryDialog="showEditCategoryDialog"
        :categoryData="category_data"
        @closeEditCategoryDialog="closeEditCategoryDialog"
        @re-fresh="getMenuOrders"
      /> -->
    </div>
  </template>
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
//   import AddCategoryDialog from "../components/dialogs/inventory/category/add-category-dialog.vue";
//   import EditCategoryDialog from "../components/dialogs/inventory/category/edit-category-dialog";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
    //   AddCategoryDialog,
    //   EditCategoryDialog,
    },
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showEditCategoryDialog: false,
        orders: [],
        category_data: {},
        tableProps: {
          border: true,
          stripe: true,
        },
        filters: [
          {
            prop: "categoryName",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getMenuOrders();
    },
  
    methods: {
      showEditCategoryData(categoryData) {
        this.showEditCategoryDialog = true;
        this.category_data = categoryData;
      },
  
      closeEditCategoryDialog() {
        this.showEditCategoryDialog = false;
      },
  
      async getMenuOrders() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`menu-orders/my-orders/${this.$store.state.userId}`);
          if (request.data.success) {
            this.orders = request.data.orders;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch menu-orders now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteCategory(categoryId) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Category. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`menu-orders/${categoryId}`);
              if (
                request.data.success &&
                request.data.message === "CATEGORY_DELETED_SUCCESSFULLY"
              ) {
                this.getMenuOrders();
                this.$notify({
                  title: "Success",
                  message: "Category deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Category, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>